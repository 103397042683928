/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";

// Keenicons - High quality and pixel perfect font icons available in 3 styles, duotone, outline and solid for Metronic elements
@import "./assets/plugins/keenicons/duotone/style.css";
@import "./assets/plugins/keenicons/outline/style.css";
@import "./assets/plugins/keenicons/solid/style.css";

.ant-btn,
.anticon {
  display: inline-flex !important;
  align-items: center !important;
  vertical-align: middle !important;
  text-align: center !important;
  justify-content: center;
}

.ant-tooltip-inner {
  font-size: 0.9rem;
}

.ant-picker,
nz-date-picker {
    font-size: 1.1rem !important;
    font-weight: 500 !important;
    line-height: 1.5 !important;
    color: var(--bs-gray-700) !important;
    appearance: none !important;
    background-clip: padding-box !important;
    border: 1px solid var(--bs-gray-300) !important;
    border-radius: 0.475rem !important;
    padding: 1rem 1rem !important;
}

.ant-steps {
  font-family: var(--bs-btn-font-family) !important;
}

.ant-pagination.mini {
  .ant-pagination-item {
    margin-right: 0.5rem !important;
  }
}

.ant-pagination {
  .ant-pagination-item {

      &.ant-pagination-item-active {
        background: var(--bs-pagination-active-bg) !important;
      }

      padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x) !important;
      font-size: var(--bs-pagination-font-size) !important;
      color: var(--bs-pagination-color) !important;
      background-color: var(--bs-pagination-bg) !important;
      border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color) !important;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;

      a {
        color: var(--bs-pagination-color) !important;
      }
  }
}

.ant-popover {
  font-family: Inter, Helvetica, "sans-serif" !important;
  border-radius: 0.625rem;
  .ant-popover-content {
    border-radius: 0.625rem;
  }
}

.mw-1300px {
  max-width: 1300px;
}

.ant-modal-content {
  border-radius: 0.625rem !important;
}

.ant-modal-header {
  border-top-left-radius: 0.625rem !important;
  border-top-right-radius: 0.625rem !important;
}

.facebook-button {
  background-color: #1877F2 !important;
  color: #fff !important;

  &:hover {
    background-color: #0e5fc9 !important;
  }
}